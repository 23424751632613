import React from "react";
import loadable from "@loadable/component";
import { Text } from "cms-caravela";
import Image from "next/image";
import { useTheme } from "../../contexts/ThemeContext";
import { useSite } from "../../hooks/useSite";
import Link from "next/link";

import { Container } from "./styles";

export default function Footer() {
  const { currentTheme } = useTheme();
  const { config } = useSite();

  return (
    <Container>
      <div className="container">
        <div className="container-endereco">
          <Link href="/">
            <Image
              src={
                currentTheme.isDark
                  ? "/img/icone/icone-logo.svg"
                  : "/img/icone/logoDark.svg"
              }
              width={207}
              height={52}
              alt="footer"
            />
          </Link>

          <div className="rd">
            <Image
              src="/img/icone/selo-rd-gold.svg"
              width={207}
              height={52}
              alt="rd station"
            />
          </div>

          <Text as="p" color="primary" fontSize="xss">
            {config?.endereco?.logradouro || ""},{" "}
            {config?.endereco?.numero || ""} - {config?.endereco?.bairro || ""}
            <br /> {config?.endereco?.cidade || ""} {config?.endereco?.estado} -
            CEP {config?.endereco?.cep || ""}
          </Text>
        </div>

        <div className="container-contato">
          <div className="tel-social">
            <div className="social">
              <Link
                href={config?.social?.instagram || ""}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src="/img/icone/insta-footer.svg"
                  width={19}
                  height={19}
                  alt="instagram"
                />
              </Link>

              <Link
                href={config?.social?.facebook || ""}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src="/img/icone/face-footer.svg"
                  width={11}
                  height={19}
                  alt="Facebook"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
