import styled from "styled-components";

export const Container = styled.footer`
  padding-top: 80px;
  padding-bottom: 68px;
  border-top: 1px solid ${({ theme }) => (theme.isDark ? "#023042" : "#d5d5d5")};
  transition: all 0.3s ease;
  .container {
    display: flex;
    .container-endereco {
      .rd {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        img {
          margin-top: 16px;
          margin-left: -46px;
        }
      }
      p {
        width: 408px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        margin-top: 43px;
        color: ${({ theme }) =>
          theme.isDark ? "#9C9C9C" : theme.colors.primaryBright};
        transition: all 0.3s ease;
      }
    }

    .container-contato {
      margin-left: auto;
      .tel-social {
        display: flex;
        align-items: center;
        margin-bottom: 66px;
        p {
          font-style: normal;
          font-weight: normal;
          line-height: 100%;
          color: ${({ theme }) =>
            theme.isDark ? "#9C9C9C" : theme.colors.primaryBright};
        }

        .social {
          width: 45px;
          margin-left: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 0px;
    .container {
      flex-direction: column;
      text-align: center;
      .container-endereco {
        .rd {
          justify-content: center;
          img {
            margin-left: 0px;
          }
        }
        p {
          width: 100%;
        }
      }

      .container-contato {
        margin-right: auto;
        .tel-social {
          flex-direction: column;
          p {
            margin-top: 63px;
            margin-bottom: 74px;
          }
          .social {
            margin-top: 32px;
            margin-left: 0px;
          }
        }
      }
    }
  }
`;
